var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseSheet', {
    staticClass: "mb-4 mt-3",
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', {
    staticClass: "px-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": 'Hovedområde*',
      "data-cy": "mainCourseId",
      "items": _vm.defaultCourseAreas,
      "item-value": "id",
      "item-text": function itemText(item) {
        return item.code + ' - ' + item.description;
      },
      "rules": _vm.validateNotEmpty,
      "clearable": ""
    },
    on: {
      "change": _vm.updateTable
    },
    model: {
      value: _vm.mainCourseId,
      callback: function callback($$v) {
        _vm.mainCourseId = $$v;
      },
      expression: "mainCourseId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": 'Kursområde*',
      "data-cy": "subCourseId",
      "no-data-text": "Velg hovedområde",
      "items": _vm.courseAreaItems,
      "item-text": function itemText(item) {
        return item.code + ' - ' + item.description;
      },
      "item-value": "id",
      "rules": _vm.validateNotEmpty,
      "required": "",
      "clearable": ""
    },
    on: {
      "change": _vm.updateTable
    },
    model: {
      value: _vm.subCourseId,
      callback: function callback($$v) {
        _vm.subCourseId = $$v;
      },
      expression: "subCourseId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": 'Undervisningsform*',
      "data-cy": "teachingMethod",
      "item-text": function itemText(item) {
        return item.charAt(0).toUpperCase() + item.slice(1);
      },
      "items": _vm.defaultTeachingMethods,
      "rules": _vm.validateNotEmpty,
      "required": "",
      "clearable": ""
    },
    on: {
      "change": _vm.updateTable
    },
    model: {
      value: _vm.teachingMethod,
      callback: function callback($$v) {
        _vm.teachingMethod = $$v;
      },
      expression: "teachingMethod"
    }
  })], 1)], 1)], 1)], 1), _vm.existingCurriculums.length === 0 ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Det eksisterer ingen studieplaner med valgte kriteria. ")]) : _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.existingCurriculums
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "createNewCourse-".concat(item.id)
                },
                on: {
                  "click": function click($event) {
                    return _vm.createNewCourse(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-arrow-right ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Velg denne studieplanen")])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }